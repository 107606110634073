export default [
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "packId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "recipient",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "totalPacksCreated",
        "type": "uint256"
      }
    ],
    "name": "PackCreated",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "opener",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "packId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amountToOpen",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "requestId",
        "type": "uint256"
      }
    ],
    "name": "PackOpenRequested",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "packId",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "opener",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "numOfPacksOpened",
        "type": "uint256"
      },
      {
        "components": [
          {
            "internalType": "address",
            "name": "assetContract",
            "type": "address"
          },
          {
            "internalType": "enum ITokenBundle.TokenType",
            "name": "tokenType",
            "type": "uint8"
          },
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "totalAmount",
            "type": "uint256"
          }
        ],
        "indexed": false,
        "internalType": "struct ITokenBundle.Token[]",
        "name": "rewardUnitsDistributed",
        "type": "tuple[]"
      }
    ],
    "name": "PackOpened",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "packId",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "requestId",
        "type": "uint256"
      }
    ],
    "name": "PackRandomnessFulfilled",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_opener",
        "type": "address"
      }
    ],
    "name": "canClaimRewards",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "claimRewards",
    "outputs": [
      {
        "components": [
          {
            "internalType": "address",
            "name": "assetContract",
            "type": "address"
          },
          {
            "internalType": "enum ITokenBundle.TokenType",
            "name": "tokenType",
            "type": "uint8"
          },
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "totalAmount",
            "type": "uint256"
          }
        ],
        "internalType": "struct ITokenBundle.Token[]",
        "name": "rewardUnits",
        "type": "tuple[]"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "components": [
          {
            "internalType": "address",
            "name": "assetContract",
            "type": "address"
          },
          {
            "internalType": "enum ITokenBundle.TokenType",
            "name": "tokenType",
            "type": "uint8"
          },
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "totalAmount",
            "type": "uint256"
          }
        ],
        "internalType": "struct ITokenBundle.Token[]",
        "name": "contents",
        "type": "tuple[]"
      },
      {
        "internalType": "uint256[]",
        "name": "numOfRewardUnits",
        "type": "uint256[]"
      },
      {
        "internalType": "string",
        "name": "packUri",
        "type": "string"
      },
      {
        "internalType": "uint128",
        "name": "openStartTimestamp",
        "type": "uint128"
      },
      {
        "internalType": "uint128",
        "name": "amountDistributedPerOpen",
        "type": "uint128"
      },
      {
        "internalType": "address",
        "name": "recipient",
        "type": "address"
      }
    ],
    "name": "createPack",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "packId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "packTotalSupply",
        "type": "uint256"
      }
    ],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "packId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountToOpen",
        "type": "uint256"
      }
    ],
    "name": "openPack",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "requestId",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_packId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_amountToOpen",
        "type": "uint256"
      },
      {
        "internalType": "uint32",
        "name": "_callBackGasLimit",
        "type": "uint32"
      }
    ],
    "name": "openPackAndClaimRewards",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  }
];